import { Component, For, onMount } from 'solid-js'
import tinycolor from "tinycolor2"
import { TextStylePreviewProps } from '~/components/text-style-preview/text-style-preview.interfaces'
import styles from '~/components/text-style-preview/text-style-preview.module.scss'
import { getSVGStyle } from '~/services/fonts/text-styles'

const TextStylePreview: Component<TextStylePreviewProps> = ({ textStyle }) => {
  const { mainTextStyle, strokesStyles, shadowsStyles } = getSVGStyle(textStyle)
  const text = textStyle.props.uppercase ? textStyle.path?.toUpperCase() : textStyle.path
  const cannotBeReadOnLightBackground = () => {
    const textIsLight = tinycolor(mainTextStyle.fill).isLight()
    const noContrastingStroke = !strokesStyles.some(stroke => tinycolor(stroke.stroke).isDark())
    return textIsLight && noContrastingStroke
  }
  const cannotBeReadOnDarkBackground = () => {
    const textIsDark = tinycolor(mainTextStyle.fill).isDark()
    const noContrastingStroke = !strokesStyles.some(stroke => tinycolor(stroke.stroke).isLight())
    return textIsDark && noContrastingStroke
  }
  const cssClasses = () => ({
    [styles.textStylePreview]: true,
    [styles.lightContrast]: cannotBeReadOnLightBackground(),
    [styles.darkContrast]: cannotBeReadOnDarkBackground()
  })
  let svgCanvas!: SVGSVGElement
  let svgText!: SVGTextElement
  let svgGroup!: SVGGElement

  onMount(() => {
    // Get the bounds of the SVG content
    const boundingBox = svgGroup.getBBox()
    let safeArea = 2
    textStyle.strokes.forEach(stroke => {
      if (stroke.width/2 > safeArea) {
        safeArea = stroke.width/2
      }
    })
    textStyle.shadows.forEach(shadow => {
      if (shadow.blur/2 > safeArea) {
        safeArea = shadow.blur/2
      }
    })
    // Set the viewport with these bounds
    svgCanvas.style.width = `${boundingBox.width + 2 * safeArea}px`
    svgCanvas.style.height = `${boundingBox.height + 2 * safeArea}px`
    svgCanvas.setAttribute("viewBox", `${boundingBox.x - safeArea} ${boundingBox.y - safeArea} ${boundingBox.width + 2*safeArea} ${boundingBox.height + 2*safeArea}`)
  })

  return (
    <svg classList={cssClasses()} ref={svgCanvas}>
      <g ref={svgGroup}>
        <For each={shadowsStyles}>
          {style => (
            <text style={style}>
              {text}
            </text>
          )}
        </For>

        <For each={strokesStyles}>
          {style => (
            <text style={style}>
              {text}
            </text>
          )}
        </For>

        <text style={mainTextStyle} ref={svgText}>
          {text}
        </text>
      </g>
    </svg>
  )
}

export default TextStylePreview
